.container {
  background: #ecebe5;
  display: block;
  margin: 0 auto;
  max-width: 1000px;
  width: 95%;
}

.title {
  text-transform: uppercase;
  color: #3B4A8E;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
}

.agenda_table {
  border: 2px solid #979797;
  margin-bottom: 60px;
}

.agenda_table_header {
  display: flex;
  background: #3B4A8E;
  color: #fff;
  padding: 17px;
  font-weight: 600;
}

.agenda_table_header span {
  text-transform: uppercase;
  font-size: 16px;
}

.agenda_table_header span:nth-child(1) {
  width: 30%;
}

.agenda_table_header span:nth-child(2) {
  width: 70%;
}

.agenda_table_body_item {
  border-bottom: 1px solid #000;
  padding: 13px 0px;
  background: #fff;
  display: flex;
}

.agenda_table_body_item:nth-child(odd) {
  background: #EDECE6;
}

.agenda_table_body_item div:nth-child(1) {
  width: 0%;
  margin-left: 2%;
  font-size: 14px;
  font-weight: 600;
}

.agenda_table_body_item div:nth-child(2) {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.agenda_table_body_item div:nth-child(2) span:nth-child(1) {
  font-size: 14px;
  width: 100%;
  display: block;
  color: #3B4A8E;
  font-weight: 600;
}

.agenda_table_body_item div:nth-child(2) span:nth-child(2) {
  width: 100%;
  font-size: 12px;
}

.agenda_speakers_table {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.agenda_speakers_table_speaker {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.agenda_speakers_table_speaker img {
  width: 50px;
  margin-right: 15px;
  border-radius: 50%;
}

.agenda_speakers_table_speaker > div {
  width: 70%;
}

.agenda_speakers_table_speaker > div span {
  display: block;
}

.agenda_speakers_table_speaker > div span:nth-child(1) {
  font-size: 14px;
  width: 100%;
  color: #3B4A8E;
  font-weight: 600;
}

.agenda_speakers_table_speaker > div span:nth-child(2) {
  font-size: 12px;
}

.partners_description {
  text-align: center;
  font-size: 12px;
}

.partners {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.partners a {
  margin: 5px 0;
  text-align: center;
  width: 100%;
}

.partners a img {
  max-height: 40px;
}

@media (min-width: 525px) {
  .agenda_speakers_table_speaker {
    justify-content: start;
  }

  .partners {
    flex-wrap: nowrap;
    justify-content: center;
  }

  .partners a {
    margin: 0 20px;
    width: initial;
  }
}

@media (min-width: 768px) {
  .title {
    font-size: 35px;
  }

  .agenda_table_header span {
    font-size: 22px;
  }

  .agenda_table_body_item div:nth-child(1) {
    font-size: 20px;
  }

  .agenda_table_body_item div:nth-child(2) span:nth-child(1) {
    font-size: 20px;
  }

  .agenda_table_body_item div:nth-child(2) span:nth-child(2) {
    font-size: 16px;
  }

  .agenda_speakers_table {
    margin-bottom: 40px;
  }

  .agenda_speakers_table_speaker {
    width: 40%;
    margin-bottom: 30px;
    margin: 0 5% 30px;
  }

  .agenda_speakers_table_speaker img {
    width: 20%;
    margin-right: 10%;
  }

  .agenda_speakers_table_speaker > div span:nth-child(1) {
    font-size: 20px;
  }

  .agenda_speakers_table_speaker > div span:nth-child(2) {
    font-size: 16px;
  }

  .partners_description {
    font-size: 20px;
  }

  .partners a img {
    max-height: 50px;
  }

}
