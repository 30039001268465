.container {
  position: sticky;
  bottom: 0;
}

.wrapper {
  background: #000002;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper p {
  width: 80%;
  margin-right: 20px;
}

.wrapper span {
  text-decoration: none;
  padding: 7px 10px;
  background: rgb(253,2,84);
  background: linear-gradient(180deg, rgba(253,2,84,1) 0%, rgba(179,49,235,1) 100%);
}

@media (min-width: 768px) {
  .container {
    font-size: 16px;
  }
}
