.headline {
  font-size: 16px;
}

.subheadline {
  font-size: 12px;
  margin: 10px -10px;
}

.landing form {
  width: 530px;
  max-width: 95%;
  padding: 10px 10px 40px 10px;
  background: #ffffff;
  position: relative;
  box-shadow: 6px 6px 6px 0 #00000085;
  text-align: center;
  box-sizing: border-box;
  margin: 0 auto;
  color: #000000;
}

.errorMessage {
  display: none;
  height: 20px;
  margin: 0 0 14px 0;
  padding: 0;
  font-size: 12px;
  font-weight: 400;
  color: #D0021B;
  position: absolute;
  bottom: -5px;
}

.error .errorMessage {
  display: block;
}

.email_group {
  position: relative;
  width: 80%;
  margin: 0 auto 30px auto;
}

input[type='email'] {
  border: 1px solid #979797;
  border-radius: 6px;
  font-size: 12px;
  height: 40px;
  padding-left: 10px;
  box-sizing: border-box;
  width: 100%;
  -webkit-appearance: none;
}

.optin_group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: initial;
  position: relative;
  width: 90%;
  margin: 35px auto 0 ;
}

input[type='checkbox'] {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}

input[type='checkbox'] + label {
  display: block;
  font-family: 'Helvetica_SG', sans-serif;
  font-size: 12px;
  line-height: 18px;
  position: relative;
}

input[type='checkbox']:checked + label::before {
  background: #3b4a8e;
  border: 1px solid #3b4a8e;
}

input[type='checkbox'] + label::before {
  content: '';
  display: inline-block;
  width: 15px;
  height: 15px;
  background: white;
  border: 1px solid #000;
  position: absolute;
  top: 0;
  left: 0;
}

input[type='checkbox'].optin-error + label::before {
  border: 1px solid #D0021B;
}

input[type='checkbox']:checked + label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  height: 3px;
  width: 8px;
  transform: rotate(-45deg);
}

.optins {
  margin-bottom: 27px;
}

.optin-label {
  font-size: 12px;
  line-height: 20px;
  padding-left: 25px;
}

.optin-link {
  text-decoration: underline;
  color: #3B4A8E;
  cursor: pointer;
}

.optin-link a {
  color: inherit;
}

.input_error {
  color: #D0021B;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0;
  position: absolute;
  bottom: -15px;
}

.submit {
  background: rgb(253,2,84);
  background: linear-gradient(123deg, rgba(253,2,84,1) 31%, rgba(179,49,235,1) 83%);
  border: none;
  border-radius: 6px;
  color: #FFFFFF;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 40px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
  width: 40%;
  -webkit-appearance: none;
}

.email_label {
  text-align: left;
  display: block;
  margin: 11px 0;
  font-weight: 500;
}

.submit[disabled] {
  opacity: .5;
  cursor: default;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #3b4a8e;
  -webkit-box-shadow: 0 0 0px 1000px #ADADAD inset;
  transition: background-color 5000s ease-in-out
}

.help {
  max-width: 469px;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  padding: 30px 20px;
}

.help a {
  color: #3B4A8E;
}

.external_container {
  margin-top: 60px;
}

.external_container p {
  text-align: center;
}

.external_container a {
  display: block;
  text-align: center;
  background: linear-gradient(123deg, rgba(253,2,84,1) 0%, rgba(179,49,235,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 10px 0;
  font-weight: 600;
}

@media (min-width: 320px) {
  .optin_group {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .landing form {
    padding: 45px;
  }

  .headline {
    font-size: 25px;
  }

  .subheadline {
    font-size: 18px;
  }

  input[type='checkbox'] + label {
    font-size: 14px;
    line-height: 20px;
  }

  .optin-label {
    font-size: 14px;
  }

  .input_error {
    bottom: -16px;
  }

  .submit {
    font-size: 14px;
  }

  .help {
    font-size: 14px;
  }
}
