.container {
  background: #ecebe5;
  display: block;
  margin: 0 auto;
  max-width: 1000px;
  width: 90%;
  text-align: center;
  font-size: 12px;
}

.container a {
  color: #3B4A8E;
}

@media (min-width: 768px) {
  .container {
    width: 85%;
    font-size: 14px;
  }
}
