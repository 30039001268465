.container {
  display: block;
  margin: 0 auto;
  max-width: 1216px;
  width: 100%;
}

.iframes_container {
  display: flex;
  flex-wrap: wrap;
}

.video_container {
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}

.chat_container {
  position: relative;
  padding-bottom: 65.25%;
  width: 100%;
}

.full_video_container{
  position: relative;
  padding-bottom: 56.25%;
  width: 100%;
}

.video, .chat {
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.info_container {
  font-size: 12px;
  margin-top: 10px;
  text-align: center;
}

.info_container .info_link {
  text-decoration: underline;
  color: #3B4A8E;
  margin: 0 4px;
  cursor: pointer;
}

.external_container {
  margin-top: 60px;
}

.external_container p {
  text-align: center;
}

.external_container a {
  display: block;
  text-align: center;
  background: linear-gradient(123deg, rgba(253,2,84,1) 0%, rgba(179,49,235,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 10px 0;
  font-weight: 600;
}

@media (min-width: 968px) {
  .container {
    width: 95%;
  }

  .info_container {
    margin-top: 15px;
    font-size: 16px;
  }

  .iframes_container {
    flex-wrap: nowrap;
  }

  .video_container {
    padding-bottom: 43.25%;
  }

  .chat_container {
    position: relative;
    padding-bottom: 43.25%;
    width: 30%;
  }
}
