@font-face {
  font-family: 'Helvetica';
  src: url('https://content.votenow.tv/app/projects/sg-open-bet/fonts/Helvetica.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica';
  src: url('https://content.votenow.tv/app/projects/sg-open-bet/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Helvetica';
  src: url('https://content.votenow.tv/app/projects/sg-open-bet/fonts/Helvetica-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

html, body {
  background-color: #0c0218;
  font-family: 'Helvetica_SG', sans-serif;
  margin: 0;
  padding: 0;
  color: #fff;
}

.app {
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin:auto;
}

header {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.partners {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 680px;
  margin: 15px auto;
}

.partners a {
  margin: 0 10px;
}

.partners a img {
  max-height: 20px;
}

.header_content {
  text-align: center;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  padding: 17px 0px 20px 0px;
}

.header_logos{
  width: 100%;
  display: flex;
  align-items: center;
}

.header_left_logo{
  text-align: left;
  width: 50%;
  padding-left: 12px;
}

.header_left_logo img{
  width: 60%;
}

.header_right_logo{
  text-align: right;
  width: 50%;
  display: inline;
  padding-right: 12px;
}

.header_right_logo img{
  width: 60%;
}

.header_title{
  margin-top:12px;
  width: 100%;
  display: block;
}

.header_content img {
  max-width: 250px;
}

.header_content h1 {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  margin: 0 auto;
  width: auto;
}

.header_content p {
  font-size: 12px;
}

.header_group {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 924px;
  position: relative;
}

.header_line {
  border-bottom: 1px solid #ADADAD;
  bottom: 25px;
  position: absolute;
  width: 100%;
}

.logo {
  background-color: #FFFFFF;
  margin-left: 12px;
  max-width: 111px;
  padding: 0 10px;
}

.headline {
  background-color: #595C5F;
  color: #FFFFFF;
  font-family: 'Helvetica', sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 12px;
  margin: 0;
  padding: 22px 10px;
}

.container {
  flex-grow: 2;
  padding: 38px 0;
  background: rgb(12,2,24);
  background: linear-gradient(143deg, rgba(12,2,24,1) 48%, rgba(37,49,101,1) 83%);
  background-size: 100%;
}

.footer_group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 924px;
  padding: 17px 14px;
  justify-content: center;
}

.footer_container {
  background: #000038;
  padding: 15px 0px;
  text-align: center;
}

footer a,
footer p,
footer span {
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

footer a {
  cursor: pointer;
}

@media (min-width: 525px) {

  .header_content h1 {
    font-size: 42px;
    line-height: 46px;
  }

  .header_content p {
    font-size: 16px;
    margin: 25px 0;
  }

  .header_left_logo img{
    width: 40%;
  }

  .header_right_logo img{
    width: 40%;
  }

  .partners {
    margin: 20px auto;
  }

  .partners a {
    margin: 0 5%;
  }

  .partners a img {
    max-height: 30px;
  }
}

@media (min-width: 768px) {
  header {
    background-size: contain;
  }



  .logo {
    max-width: 223px;
  }

  .headline {
    font-size: 16px;
    letter-spacing: -0.48px;
    line-height: 19px;
    padding: 22.5px;
  }

  footer a,
  footer p,
  footer span {
    font-size: 16px;
    letter-spacing: -0.4px;
    line-height: 17px;
  }

  footer a:first-child {
    margin-left: 0;
  }

}

@media (min-width: 924px) {

  .header_content{
    margin: 40px auto 0px;
  }

  .logo {
    margin-left: 0;
  }

}
