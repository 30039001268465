.container {
  background: #3B4A8E;
}

.navigation {
  padding: 15px 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
}

.navigation li {
  color: #ACBAF6;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 10px;
  cursor: pointer;
  font-size: 14px;
}

.navigation li a {
  color: inherit;
  text-decoration: none;
}

.activeLink {
  color: #fff !important;
}

.navigation li::after {
  content: "|";
  margin-left: 20px;
}

.navigation li:last-child::after {
  margin-left: 0;
  content: "";
}

@media (min-width: 768px) {
  .navigation {
    padding: 20px 0;
  }

  .navigation li {
    font-size: 18px;
  }
}
